import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import dayjs from 'dayjs'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { useFeature } from '@growthbook/growthbook-react'

import { useDispatch, useSelect } from 'store/index'

import { openDatePicker, setGuestPickerFocused } from 'reducers/uiState'

import { selectIsMobile } from 'selectors/uiState'

import { BookingContext } from 'context/BookingContext'

import useAugmentedRouter from 'hooks/useAugmentedRouter'
import useAnalytics from 'hooks/useAnalytics'

import BookingQuote from 'components/BookingQuote'
import useBookButtonLabel from 'components/BookingQuote/hooks/useBookButtonLabel'
import useBookingErrors from 'components/BookingQuote/hooks/useBookingErrors'

import ViewDetailsBar from '../ViewDetailsBar'
import { ListingAmenities } from '../ListingPage.types'

import { calculateTotalPriceForHit, convertErrorCode } from 'utils/Listings'
import { sanitizeQueryParams } from 'utils/Search'
import urlToSearchState from 'utils/search/urlToSearchState'
import { pushToDataLayer } from 'utils/Gtm'
import capitalize from 'utils/strings/capitalize'
import { scrollToId } from 'utils/scroll'
import type { CombinedListing } from 'utils/staticData'

import type { Refunds } from 'types/externalData'

type QuoteProps = {
  availability?: any[]
  availabilityAvgPrice?: number
  className?: string
  listing: CombinedListing
  minStay?: any
  viewDetailsOpen: boolean
  setViewDetailsOpen: (isOpen: boolean) => void
  amenities: Array<ListingAmenities>
  quoteError: any
  isValidatingQuote: boolean
  isLoadingQuote: boolean
  cancellationRefunds?: Refunds
  quoteErrorMessage: string
  quoteData: any
}

const Quote: React.FC<QuoteProps> = ({
  availabilityAvgPrice,
  className,
  listing,
  minStay,
  viewDetailsOpen,
  setViewDetailsOpen,
  amenities,
  quoteError,
  isLoadingQuote,
  cancellationRefunds,
  quoteErrorMessage,
  quoteData,
  isValidatingQuote,
}) => {
  const { clickProductSection } = useAnalytics()
  const router = useAugmentedRouter()
  const {
    setLoadingQuote,
    quote,
    setQuote,
    bookingRef,
    guests,
    updateGuests,
    dates,
    updateDates,
  } = useContext(BookingContext)
  const { errors: bookingErrors, hasErrors: hasBookingErrors } =
    useBookingErrors()
  const [isRedirectingToNextPage, setIsRedirectingToNextPage] = useState(false)
  const { invalidQuote } = useAnalytics()
  const isMobile = useSelect(selectIsMobile)
  const questionModalOpen = useSelect(
    (state) => state.resultDetail.questionModalOpen,
  )
  const selectedListings = useSelect(
    (state) => state.favorites.selectedListings,
  )
  const bookNowButtonText = useBookButtonLabel()

  const { on: showMedianPrice } = useFeature('avg-price-v2')

  useEffect(() => {
    if (quoteError?.info?.errors[0]?.errorCode === 1016) {
      updateDates({ start: undefined, end: undefined }, true)
    }
  }, [quoteError, updateDates])

  useEffect(() => {
    setLoadingQuote(isLoadingQuote)
  }, [isLoadingQuote, setLoadingQuote])

  const [quoteErrorMsg, setQuoteErrorMsg] = useState(quoteErrorMessage)

  const searchState = useMemo(
    () => urlToSearchState(router.asPath),
    [router.asPath],
  )

  const appDispatch = useDispatch()

  const handleApplyDates = useCallback(
    (startDate, endDate) => {
      updateDates({ start: startDate, end: endDate }, true)
      if (!guests.adults) {
        appDispatch(setGuestPickerFocused(true))
      }
    },
    [updateDates, appDispatch, guests],
  )

  const handleClearDates = useCallback(() => {
    updateDates({ start: undefined, end: undefined }, true)
    setQuote(null)
  }, [updateDates, setQuote])

  const handleApplyGuests = useCallback(
    (adultGuests, childGuests, infantGuests, petGuests, totalGuests) => {
      updateGuests(
        {
          adults: adultGuests,
          children: childGuests,
          infants: infantGuests,
          pets: petGuests,
          total: totalGuests,
        },
        true,
      )
      pushToDataLayer('guestCount', { guestCount: totalGuests })
      pushToDataLayer('petsAdded', { petsAdded: petGuests === 1 })
    },
    [updateGuests],
  )

  const handleOnClearGuests = () => {
    updateGuests(
      { adults: 0, children: 0, infants: 0, pets: 0, total: 0 },
      true,
    )
    pushToDataLayer('guestCount', { guestCount: '0' })
    pushToDataLayer('petsAdded', { petsAdded: false })
    setQuote(null)
  }

  const handleBtnClick = () => {
    const { start, end } = dates ?? {}
    if (
      listing.objectID &&
      dates &&
      start &&
      end &&
      guests &&
      guests['adults'] > 0 &&
      guests['total'] > 0 &&
      !hasBookingErrors
    ) {
      setIsRedirectingToNextPage(true)
      bookingRef.current && enableBodyScroll(bookingRef.current),
        pushToDataLayer('add_to_cart', {
          ecommerce: {
            currency: 'USD',
            value: quote?.price?.total.toString(),
            items: [
              {
                item_id: listing.objectID,
                item_name: listing?.Headline,
                item_category: listing?.units?.[0].type,
                price: quote?.price.total,
                quantity: 1,
              },
            ],
          },
          favoriteListing: selectedListings.some(
            (favorite) => favorite.objectID === listing?.objectID,
          ),
        })

      pushToDataLayer('begin_checkout', {
        content_ids: [listing.objectID],
        city: listing?.City ? listing.City : 'multi',
        region: listing?.State ? listing.State : 'multi',
        country: listing?.Country ? listing.Country : 'multi',
        travel_start: dates?.start
          ? dayjs(dates?.start).format('YYYY-MM-DD')
          : '',
        travel_end: dates?.end ? dayjs(dates?.end).format('YYYY-MM-DD') : '',
        num_adults: guests?.adults ? guests.adults : '0',
        num_children: guests?.children ? guests.children : '0',
        value: quote?.price?.total,
        currency: 'USD',
        ecommerce: {
          value: quote?.price?.total,
          currency: 'USD',
          items: [
            {
              item_id: listing.objectID,
              item_name: listing?.Headline,
              item_category: listing?.units?.[0].type,
              price: quote?.price.total,
              quantity: 1,
            },
          ],
        },
      })

      const bookingParams = sanitizeQueryParams(searchState)
      const queryString = new URLSearchParams(bookingParams).toString()

      router.push(
        `/booking/${listing.objectID}/summary${
          queryString ? '?' + queryString : ''
        }`,
      )
    } else if (!start || !end || bookingErrors.dates) {
      if (isMobile && !viewDetailsOpen) {
        bookingRef.current && disableBodyScroll(bookingRef.current)
        setViewDetailsOpen(true)
      }
      appDispatch(openDatePicker())
    } else if (!guests || !guests.adults || bookingErrors.guests) {
      if (isMobile && !viewDetailsOpen) {
        bookingRef.current && disableBodyScroll(bookingRef.current)
        setViewDetailsOpen(true)
      }
      appDispatch(setGuestPickerFocused(true))
    }
  }

  useEffect(() => {
    if (quoteData && !quoteErrorMessage) {
      setQuote(quoteData)
      setQuoteErrorMsg('')
    } else if (quoteErrorMessage) {
      let errorMsg = quoteErrorMessage

      if (!dates || !dates.start || !dates.end) {
        errorMsg = convertErrorCode('missingDates')
      }

      setQuoteErrorMsg(errorMsg)
      invalidQuote({
        errorResponse: errorMsg,
        productId: listing.objectID,
        page: 'product viewed',
      })

      setQuote(null)
    }
  }, [
    quoteData,
    setQuote,
    quoteErrorMessage,
    dates,
    invalidQuote,
    listing.objectID,
  ])

  useEffect(() => {
    const handleLeavePage = () => {
      pushToDataLayer('begin_checkout', {
        content_ids: [listing.objectID],
        city: listing?.City ? listing.City : 'multi',
        region: listing?.State ? listing.State : 'multi',
        country: listing?.Country ? listing.Country : 'multi',
        travel_start: dates?.start
          ? dayjs(dates?.start).format('YYYY-MM-DD')
          : '',
        travel_end: dates?.end ? dayjs(dates?.end).format('YYYY-MM-DD') : '',
        num_adults: guests?.adults ? guests.adults : '0',
        num_children: guests?.children ? guests.children : '0',
        value: quote?.price.total,
        currency: 'USD',
      })
    }

    window.addEventListener('beforeunload', handleLeavePage)

    return () => {
      window.removeEventListener('beforeunload', handleLeavePage)
    }
  }, [dates, guests, quote, listing])

  const trackViewDetailsModalToggle = useCallback(
    (isOpen: boolean) => {
      clickProductSection({
        listingId: listing?.objectID,
        section: 'Price Modal',
        action: isOpen ? 'Open' : 'Close',
      })
    },
    [listing.objectID],
  )

  const toggleViewDetailsModal = useCallback(
    (isOpen: boolean) => {
      setViewDetailsOpen(isOpen)
      if (!bookingRef.current) return

      if (isOpen) {
        disableBodyScroll(bookingRef.current)
      } else {
        enableBodyScroll(bookingRef.current)
      }

      trackViewDetailsModalToggle(isOpen)
    },
    [setViewDetailsOpen, trackViewDetailsModalToggle, bookingRef],
  )

  const scrollToReviews = (): void => {
    scrollToId('reviews')
  }

  const isModalModeOn = !questionModalOpen && viewDetailsOpen

  useEffect(() => {
    if (!isMobile && isModalModeOn) {
      toggleViewDetailsModal(false)
    }
  }, [isMobile, isModalModeOn, toggleViewDetailsModal])

  const trackExpandCollapsePriceBreakdown = useCallback(
    (item: string, isExpanded: boolean) => {
      clickProductSection({
        listingId: listing?.objectID,
        section: 'Price Box',
        clicked: capitalize(item),
        action: isExpanded ? 'Open' : 'Close',
      })
    },
    [listing, clickProductSection],
  )
  const isLoading = isLoadingQuote || isValidatingQuote

  const nights = dayjs(dates?.end).diff(dayjs(dates?.start), 'days')

  let averagePrice = (quote?.price?.total || 1) / nights || availabilityAvgPrice

  if (showMedianPrice && (!dates?.end || !dates?.start)) {
    const stayLength = 5
    const totalPrice = calculateTotalPriceForHit(
      listing,
      dates?.start,
      dates?.end,
      stayLength,
      guests.pets > 0,
      showMedianPrice,
    )
    averagePrice = totalPrice / stayLength
  }

  return (
    <>
      <BookingQuote
        cancellationRefunds={cancellationRefunds}
        className={className}
        isLoadingQuote={isLoading}
        isMobile={isMobile}
        isModalModeOn={!questionModalOpen && viewDetailsOpen}
        numberOfReviews={listing['Number of Reviews']}
        quoteServiceError={isLoading ? undefined : quoteErrorMsg}
        toggleModalMode={toggleViewDetailsModal}
      >
        <BookingQuote.Total price={quote?.price?.total} />
        <BookingQuote.AvgPrice price={averagePrice} />
        <BookingQuote.RatingAndReview
          onClick={scrollToReviews}
          rating={listing['Average Rating']}
        />
        <BookingQuote.Dates
          listingId={listing.objectID}
          minStay={minStay}
          onApplyDates={handleApplyDates}
          onClearDates={handleClearDates}
          validateOnDirty
        />
        <BookingQuote.Guests
          amenities={amenities}
          listing={listing}
          onApplyGuests={handleApplyGuests}
          onClearGuests={handleOnClearGuests}
          validateOnDirty
        />
        <BookingQuote.PriceBreakdown
          onToggleExpandPriceItem={trackExpandCollapsePriceBreakdown}
          quote={quote}
        />
        <BookingQuote.Button
          announcement="You won’t be charged yet."
          isLoading={isRedirectingToNextPage}
          onClick={questionModalOpen ? undefined : handleBtnClick}
        />
      </BookingQuote>
      {!questionModalOpen && isMobile && (
        <ViewDetailsBar
          avgPrice={
            (showMedianPrice ? averagePrice : availabilityAvgPrice) ?? 0
          }
          bookNowButtonText={bookNowButtonText}
          isButtonDisabled={
            (!!quoteErrorMessage &&
              !bookingErrors.dates &&
              !bookingErrors.guests) ||
            isLoading
          }
          onClickBookBtn={handleBtnClick}
          onViewDetailsClick={toggleViewDetailsModal}
          show={!viewDetailsOpen}
        />
      )}
    </>
  )
}

export default Quote
